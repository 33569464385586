import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en.json";
import translationFR from "./locales/fr.json";

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

const browserLanguage = navigator.language.split("-")[0]; // Get the first part of the language code, e.g., 'en' from 'en-US'

i18n.use(initReactI18next).init({
  resources,
  lng: resources[browserLanguage] ? browserLanguage : "en", // Use browser language if available, otherwise default to 'en'
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
