import React, { useState, useEffect, useMemo } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAnalyticsEventTracker from "../Analytics/useAnalyticsEventTracker";
import "../css/RegistrationForm.css";
import FormLabel from "../components/FormLabel";
import Waves from "../components/Waves";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import axios from "axios";
import { useMediaQuery, useTheme } from "@mui/material";
import { useUser } from "../context/UserContext";

const phoneRegex = /^\+?[1-9]\d{1,14}$/;

const RegistrationForm = () => {
  const ReactGA = useAnalyticsEventTracker();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { setUser } = useUser();

  const [values, setValues] = useState({
    phoneNumber: "",
    name: "",
    email: "",
    formattedValue: null,
    phoneNumberFormat: null,
  });
  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/confirmation");
    }
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setValues({
      ...values,
      phoneNumber: value,
      formattedValue: formattedValue,
      phoneNumberFormat: data.format,
    });
  };

  const saveUserAndSendSMSWithTwilio = async (user) => {
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/user/send-sms`,
        {
          phone_number: values.phoneNumber,
          user: user,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response.data;
        if (data.loggedToken) {
          setUser(data.user);
          localStorage.setItem("token", data.loggedToken);
          navigate("/");
        } else {
          localStorage.setItem("tempToken", data.token);
          navigate("/confirmation", {
            state: { phone_number: values.formattedValue },
          });
        }
        ReactGA("User", "Authenticated");
        setValid(true);
        toast.success("Registration successful!");
      })
      .catch((error) => {
        setValid(false);
        toast.error(
          `An error occurred during registration! Please contact the support team.`
        );
      });
  };

  const verifiedInputs = useMemo(() => {
    if (
      !values.phoneNumber ||
      !values.name ||
      !values.email ||
      values.formattedValue.length !== values.phoneNumberFormat.length
    ) {
      return false;
    }
    const phoneNumber = values.phoneNumber.replace(/\s/g, "");
    if (!phoneRegex.test(phoneNumber)) {
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(values.email)) {
      return false;
    }

    return true;
  }, [values]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!verifiedInputs) {
      return;
    }

    setSubmitted(true);

    let user = {
      name: values.name,
      email: values.email,
      phone_number: values.phoneNumber,
    };

    saveUserAndSendSMSWithTwilio(user);
  };

  return (
    <div className="page-container">
      <div className="form-container">
        <ToastContainer />
        <p className="form-title">Welcome to this BETA-TEST of</p>
        <p className="form-name">Andrew The Doc</p>
        <p className="form-subtitle">
          Please ensure you log in with a unique account it will be verified.
        </p>
        <div className="register-form">
          {submitted && valid && (
            <div className="success-message">
              <h3>Welcome {values.name}</h3>
              <div>Your registration was successful!</div>
            </div>
          )}

          {!valid && (
            <div className="form-group">
              <div>
                <FormLabel text="Phone Number" required />
                <PhoneInput
                  country={"fr"}
                  value={values.phoneNumber}
                  autoFormat={true}
                  onChange={handlePhoneChange}
                  containerClass="phone-input-container"
                  inputClass="form-field"
                  buttonClass="phone-input-button"
                  required
                />
              </div>

              <div>
                <FormLabel text="Name" required />
                <CustomInput
                  value={values.name}
                  onChange={handleInputChange}
                  name="name"
                  required
                  type="text"
                  placeholder="Andrew"
                />
              </div>

              <div>
                <FormLabel text="Email" required />
                <CustomInput
                  value={values.email}
                  onChange={handleInputChange}
                  name="email"
                  required
                  type="email"
                  placeholder="exemple@exemple.com"
                />
              </div>

              <CustomButton
                label="Register"
                style={{ marginTop: "12px" }}
                disabled={!verifiedInputs}
                type="submit"
                onClick={handleSubmit}
              />
            </div>
          )}
        </div>
      </div>
      <Waves />
    </div>
  );
};

export default RegistrationForm;
