import { Route, Routes, useNavigate } from "react-router-dom";
import ChatbotComponent from "../pages/Chatbot";
import Confirmation from "../pages/Confirmation";
import FeedbackForm from "../pages/Feedback";
import RegistrationForm from "../pages/Signup";
import PrivateRoutes from "./PrivateRoutes";
import { useEffect } from "react";
import Navbar from "../components/Navbar";
import TermsOfService from "../pages/TermsOfService";
import { useUser } from "../context/UserContext";
import Loading from "../pages/Loading";

const CustomRoutes = () => {
  const token = localStorage.getItem("token");
  const tempToken = localStorage.getItem("tempToken");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token && !tempToken) {
      navigate("/login");
    } else if (tempToken) {
      navigate("/confirmation");
    }
  }, [navigate, tempToken, token]);

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "hidden",
        flexDirection: "row",
      }}
    >
      {token && <Navbar />}

      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route element={<ChatbotComponent />} path="/" />
          <Route element={<FeedbackForm />} path="/feedback" />
          <Route element={<TermsOfService />} path="/terms" />
        </Route>

        {!token && <Route element={<RegistrationForm />} path="/login" />}

        {tempToken && <Route element={<Confirmation />} path="/confirmation" />}

        <Route path="/loading" element={<Loading />} />
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </div>
  );
};

export default CustomRoutes;
