import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiSend } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAnalyticsRegisterTracker from "../Analytics/useAnalyticsEventTracker";
import Effect from "../components/Chat/Effect";
import CustomInputWithButton from "../components/CustomInputWithButton";
import "../css/ChatbotPage.css";
import { checkLanguage, transformMessages } from "../utils/chatbot";
import Typewriter from "../hooks/Typewriter";
import { useUser } from "../context/UserContext";

const ChatbotComponent = () => {
  const [inputText, setInputText] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [chatUsable, setChatUsable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const trackEvent = useAnalyticsRegisterTracker();
  const chatContainerRef = useRef(null);
  const { user, setUser } = useUser();

  const userLang = navigator.language || navigator.userLanguage;
  const textLang = checkLanguage(userLang);

  useEffect(() => {
    setChatHistory([{ user: false, message: textLang }]);

    setTimeout(() => {
      setChatUsable(true);
    }, 3000);
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleSendMessage = useCallback(async () => {
    if (!inputText.trim() || !chatUsable) {
      return;
    }

    setChatUsable(false);
    setIsLoading(true);
    setChatHistory((prevState) => [
      ...prevState,
      { user: true, message: inputText },
    ]);
    setInputText("");
    trackEvent("Chatbot", "Message Sent");

    const new_chatHistory = [
      ...chatHistory,
      { user: true, message: inputText },
    ];

    if (user.messages_limit > 0) {
      setUser({
        ...user,
        messages_limit: user.messages_limit - 1,
      });
    }

    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/chat/sendMessage`, {
        message: inputText,
        token: localStorage.getItem("token"),
        old_messages: transformMessages(new_chatHistory),
      })
      .then(({ data }) => {
        setChatHistory((prevState) => [
          ...prevState,
          { user: false, message: data.response.text },
        ]);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.data.message === "NO_MESSAGES_LEFT") {
          toast.error(
            `You have no messages left. Please share your opinion on the feedback page.`
          );
        } else {
          toast.error(`An error occurred while sending message`);
          setUser({
            ...user,
            messages_limit: user.messages_limit + 1,
          });
          setChatUsable(true);
        }
      });
  }, [chatUsable, inputText]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleSendMessage();
      }
    };
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleSendMessage]);

  return (
    <div className="container">
      <ToastContainer />
      <div className="headerContainer">
        <span className="headerText"></span>
        <span
          className="headerText"
          style={{
            marginLeft: "35px",
          }}
        >
          Andrew the Doc
        </span>
        <span className="headerText">{user?.messages_limit}/5</span>
      </div>
      <div className="chatContainer" ref={chatContainerRef}>
        {chatHistory.map((chat, index) => (
          <div
            key={index}
            className={`chatBubble ${chat.user ? "userBubble" : "botBubble"}`}
          >
            <div
              className="chatText"
              style={
                chat.user
                  ? { color: "white", fontSize: 14, textAlign: "left" }
                  : {}
              }
            >
              {chat.user ? (
                <p>{chat.message}</p>
              ) : (
                <Typewriter
                  markdown
                  text={chat.message}
                  finishCallback={() => setChatUsable(true)}
                  containerRef={chatContainerRef}
                />
              )}
            </div>
          </div>
        ))}
        {isLoading && (
          <div className="typingIndicator">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        )}
      </div>
      <div className="inputContainer">
        <CustomInputWithButton
          label={<FiSend size={18} />}
          disabled={!chatUsable}
          onClick={handleSendMessage}
          onTextChange={(e) => setInputText(e.target.value)}
          inputValue={inputText}
        />
      </div>
      <Effect />
    </div>
  );
};

export default ChatbotComponent;
