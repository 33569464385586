import React from "react";
import { useUser } from "./UserContext";

const UserLoader = ({ children }) => {
  const { loading } = useUser();

  if (loading) {
    throw new Promise(() => {}); // This triggers the Suspense fallback
  }

  return children;
};

export default UserLoader;
