import React, { useState, useEffect } from "react";
import Markdown from "react-markdown";

const Typewriter = ({
  text,
  delay = 15,
  finishCallback,
  markdown = false,
  containerRef,
}) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);

      // Scroll to the bottom of the container
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }

      return () => clearTimeout(timeout);
    }

    if (currentIndex === text.length && finishCallback) {
      finishCallback();
    }
  }, [currentIndex, delay, text]);

  return markdown ? (
    <Markdown
      components={{
        p: ({ node, ...props }) => <p {...props} className="chat-paragraph" />,
        ol: ({ node, ...props }) => (
          <ol {...props} className="chat-ordered-list" />
        ),
        ul: ({ node, ...props }) => (
          <ul {...props} className="chat-unordered-list" />
        ),
        li: ({ node, ...props }) => (
          <li {...props} className="chat-list-item" />
        ),
      }}
    >
      {currentText}
    </Markdown>
  ) : (
    currentText
  );
};

export default Typewriter;
