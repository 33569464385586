import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/Confirmation.css";
import CustomButton from "../components/CustomButton";
import Waves from "../components/Waves";
import axios from "axios";

const Confirmation = () => {
  const [inputs, setInputs] = useState(Array(6).fill(""));
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { phone_number } = location.state || {};

  useEffect(() => {
    if (!localStorage.getItem("tempToken") && !localStorage.getItem("token")) {
      navigate("/login");
    } else if (
      !localStorage.getItem("tempToken") &&
      localStorage.getItem("token")
    ) {
      navigate("/");
    }
  }, [navigate]);

  const handleChange = (e, index) => {
    if (inputs[index] !== "") {
      return;
    }
    const value = e.target.value;
    if (/^\d$/.test(value)) {
      const newInputs = [...inputs];
      newInputs[index] = value;
      setInputs(newInputs);
      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  useEffect(() => {
    if (inputs.every((input) => input !== "")) {
      handleSubmit();
    }
  }, [inputs]);

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newInputs = [...inputs];
      if (inputs[index] !== "") {
        newInputs[index] = "";
      } else if (index > 0) {
        newInputs[index - 1] = "";
        inputRefs.current[index - 1].focus();
      }
      setInputs(newInputs);
    }

    if (e.target.value.length === 1 && /^\d$/.test(e.key)) {
      const newInputs = [...inputs];
      newInputs[index] = e.key;
      setInputs(newInputs);
      if (index < 5) {
        e.preventDefault();
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData("text");
    if (/^\d{6}$/.test(paste)) {
      const newInputs = paste.split("").map((digit) => digit);
      setInputs(newInputs);
      inputRefs.current[5].focus();
    }
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (
      !localStorage.getItem("tempToken") ||
      localStorage.getItem("tempToken") === undefined
    ) {
      toast.error("Temporary token is missing or invalid.");
      return;
    } else if (localStorage.getItem("token")) {
      navigate("/");
      return;
    }

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/user/confirm-code`,
        {
          code: inputs.join(""),
          token: localStorage.getItem("tempToken"),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response.data;
        localStorage.setItem("token", data.token);
        localStorage.removeItem("tempToken");
        navigate("/");
      })
      .catch((error) => {
        toast.error("An error occurred during confirmation!");
      });
  };

  return (
    <div className="confirmation-container">
      <ToastContainer />
      <div className="confirmation-text-container">
        <p className="confirmation-heading">
          Enter the <span className="bold">6-digit</span> code sent to your
          phone to confirm your account
        </p>
        <p className="confirmation-subheading">
          We sent the code to <span className="bold">{phone_number}</span>
        </p>
      </div>
      <form onSubmit={handleSubmit} className="confirmation-form">
        <div className="inputs-container" onPaste={handlePaste}>
          {inputs.map((input, index) => (
            <input
              key={index}
              type="text"
              value={input}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              maxLength={1}
              ref={(el) => (inputRefs.current[index] = el)}
              className="confirmation-input-phone-number"
              style={{
                border: input && "2px solid #3f51b5",
              }}
            />
          ))}
        </div>
        <CustomButton label="Confirm Account" />
      </form>
      <Waves rbgColor={"63, 81, 181"} />
    </div>
  );
};

export default Confirmation;
