import React from "react";
import { DNA } from "react-loader-spinner";
import "../css/Loading.css";

export default function Loading() {
  return (
    <div className="loading-container">
      <DNA
        visible={true}
        height="125"
        width="125"
        ariaLabel="dna-loading"
        wrapperStyle={{}}
        wrapperClass="dna-wrapper"
      />
      <p className="loading-text">Loading, please wait...</p>
    </div>
  );
}
