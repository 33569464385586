const transformMessages = (messages) => {
  return messages.map((message) => ({
    content: message.message,
    role: message.user ? "user" : "assistant",
  }));
};

const checkLanguage = (userLang) => {
  if (userLang === "en-US" || userLang === "en") {
    return "Hello! How can I help you today? If you are facing any issues, please tell me more about your gender and your age first so I can get a better diagnosis.";
  } else if (userLang === "fr-FR" || userLang === "fr") {
    return "Bonjour! Comment puis-je vous aider aujourd'hui? Si vous rencontrez des problèmes, veuillez me parler de votre sexe et de votre âge pour que je puisse obtenir un meilleur diagnostic.";
  } else if (userLang === "es-ES" || userLang === "es") {
    return "¡Hola! ¿Cómo puedo ayudarte hoy? Si estás enfrentando algún problema, por favor dime más sobre tu género y tu edad para que pueda obtener un mejor diagnóstico.";
  } else if (userLang === "pt-BR" || userLang === "pt") {
    return "Olá! Como posso te ajudar hoje? Se você está enfrentando algum problema, por favor me fale mais sobre seu gênero e sua idade para que eu possa obter um diagnóstico melhor.";
  } else {
    return "Hello! How can I help you today? If you are facing any issues, please tell me more about your gender and your age first so I can get a better diagnosis.";
  }
};

export { transformMessages, checkLanguage };
