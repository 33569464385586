import React, { useState } from "react";
import "../css/Navbar.css"; // Import CSS for styling

// Import icons from react-icons
import { BsBriefcaseFill, BsBriefcase } from "react-icons/bs";
import { FiMenu, FiX } from "react-icons/fi";
import { RiRobot3Fill, RiRobot3Line } from "react-icons/ri";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";

const routes = [
  {
    title: "ChatBot",
    path: "/",
    cName: "nav-links",
    icon: <RiRobot3Line size={18} />,
    selectedIcon: <RiRobot3Fill size={18} color="#3f51b5" />,
  },
  {
    title: "Feedback",
    path: "/feedback",
    cName: "nav-links",
    icon: <FaRegHeart size={18} />,
    selectedIcon: <FaHeart size={18} color="#3f51b5" />,
  },
  {
    title: "Terms of Service",
    path: "/terms",
    cName: "nav-links",
    icon: <BsBriefcase size={18} strokeWidth={0.5} />,
    selectedIcon: <BsBriefcaseFill size={18} color="#3f51b5" />,
  },
];

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={`navbar ${isOpen ? "open" : "closed"}`}>
      <div style={isMobile ? {} : { borderBottom: "1px solid #f5f6f7" }}>
        <div className="menu-icons">
          {!isOpen ? (
            <FiMenu
              className="menu-icon"
              onClick={toggleNavbar}
              size={18}
              color={
                location.pathname === "/feedback" && isMobile
                  ? "white"
                  : "black"
              }
            />
          ) : (
            <FiX
              className="menu-icon"
              onClick={toggleNavbar}
              size={18}
              color={
                location.pathname === "/feedback" && isMobile
                  ? "white"
                  : "black"
              }
            />
          )}
        </div>
      </div>

      <ul className={`nav-menu ${isMobile && !isOpen ? "closed" : "open"}`}>
        {routes.map((route, index) => {
          return (
            <li key={index}>
              <Link className={route.cName} to={route.path}>
                <span className="nav-icon">
                  {location.pathname === route.path
                    ? route.selectedIcon
                    : route.icon}
                </span>
                {isOpen && <span className="nav-title">{route.title}</span>}
              </Link>
            </li>
          );
        })}
      </ul>

      <div className="nav-footer"></div>
    </nav>
  );
};

export default Navbar;
