import React, { useState, useEffect } from "react";
import {Modal, Box, Typography, Button, useMediaQuery, useTheme} from "@mui/material";
import { EmbedPDF } from "@simplepdf/react-embed-pdf";


function TosModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallHeight = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: isMobile? "relative": 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '70vw' :400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
    overflow: 'auto',
    maxHeight: isSmallHeight ? '80vh' : 'auto'
  };

  useEffect(() => {
    setIsModalOpen(localStorage.getItem("termsAccepted") !== "true");
  }, []);

  const handleClose = () => {
    localStorage.setItem("termsAccepted", true);
    setIsModalOpen(false);
  };

  return (
    <Modal
    open={isModalOpen}
    onClose={handleClose}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
    style={{ overflow:'auto'}}
    >
    <Box sx={style}>
        <Typography id="modal-title" variant={isMobile ? 'h6': "h4"} component="h2">
        Terms of Service
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
        By using this service, you agree to our terms of service and privacy policy.
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
        This service is not a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on this website.
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
        If you think you may have a medical emergency, call your doctor or your urgence number immediately. This service does not recommend or endorse any specific tests, physicians, products, procedures, opinions, or other information that may be mentioned on the website. Reliance on any information provided by this service is solely at your own risk.
        </Typography>

        {/* Add a link to the CGU using the PDF */}
        <Typography id="modal-description" sx={{ mt: 2 }}>
        <EmbedPDF>
        <a href="https://sbrt4qp6.simplepdf.eu/form/b23f14b5e95d155e1ca1fbd0d61f3bc61315d9f24d5b0c0da0148c59040f3218">
            Read our terms of service
        </a>
        </EmbedPDF>
        </Typography>
        <Button variant="contained" color="primary" onClick={handleClose} sx={{ mt: 3 }}>
        Accept
        </Button>
    </Box>
    </Modal>
  );
}

export default TosModal;
