import { useEffect } from "react";

const CustomInput = ({
  value,
  handleKeyPress,
  disabled = false,
  onChange,
  name,
  required = false,
  type = "text",
  placeholder,
  style,
}) => {
  return (
    <input
      disabled={disabled}
      className="custom-input"
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyPress}
      placeholder={placeholder}
      name={name}
      required={required}
      type={type}
      style={style}
    />
  );
};

export default CustomInput;
