const CustomInputWithButton = ({
  label,
  handleKeyPress,
  disabled = false,
  onClick,
  onTextChange,
  inputValue,
}) => {
  return (
    <div className="custom-input-with-button-container">
      <textarea
        type="text"
        placeholder="Type your request here..."
        className="custom-input-with-button input"
        onKeyDown={handleKeyPress}
        disabled={disabled}
        onChange={onTextChange}
        value={inputValue}
      />
      <button
        className="custom-input-with-button button"
        disabled={disabled}
        onClick={onClick}
      >
        {label}
      </button>
    </div>
  );
};

export default CustomInputWithButton;
