import { useEffect } from "react";

const CustomButton = ({
  label,
  onClick,
  squared = false,
  disabled = false,
  style,
}) => {
  return (
    <button
      type="submit"
      className={`custom-button ${squared ? "squared" : ""} ${
        disabled ? "disabled" : ""
      }`}
      onClick={onClick ? onClick : null}
      disabled={disabled}
      style={style}
    >
      {label}
    </button>
  );
};

export default CustomButton;
