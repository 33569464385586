import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Create a UserContext
const UserContext = createContext();

// Custom hook to use the UserContext
const useUser = () => useContext(UserContext);

// UserProvider component
const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token"); // or however you store your token

  useEffect(() => {
    if (!localStorage.getItem("tempToken") && !localStorage.getItem("token")) {
      navigate("/login");
    } else if (localStorage.getItem("tempToken")) {
      navigate("/confirmation");
    }
  }, [navigate]);

  useEffect(() => {
    const getUser = async () => {
      setLoading(true);

      try {
        if (!token) {
          setLoading(false);
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/user/data?token=${token}`
        );
        setUser(response.data);
      } catch (error) {
        localStorage.removeItem("token");
        navigate("/login");
      } finally {
        setTimeout(() => setLoading(false), 500);
      }
    };

    getUser();
  }, [token]);

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, useUser };
