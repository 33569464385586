import ReactGA from "react-ga4";

const useAnalyticsEventTracker = () => {
  const trackEvent = (category, action) => {
    ReactGA.event({
      category,
      action,
    });
  };

  return trackEvent;
};

export default useAnalyticsEventTracker;
