import React from "react";
import ReactGA from "react-ga4";
import "./App.css";
import CustomRoutes from "./navigation/Routes";
import TosModal from "./components/TosModal";
import Hotjar from "@hotjar/browser";
import "./i18n"; // Import the i18n configuration

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
const siteId = process.env.REACT_APP_HOTJAR_ID;
const hotjarVersion = 6;
ReactGA.initialize(TRACKING_ID);
Hotjar.init(siteId, hotjarVersion);

function App() {
  return (
    <div className="App">
      <TosModal />
      <CustomRoutes />
    </div>
  );
}

export default App;
