import React from "react";
import { useTranslation } from "react-i18next";
import "../css/TermsOfService.css";

const TermsAndConditions = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="terms-page-wrapper">
      <div className="language-container">
        <select
          onChange={(e) => changeLanguage(e.target.value)}
          defaultValue={i18n.language}
        >
          <option value="en">English</option>
          <option value="fr">French</option>
        </select>
      </div>

      <div className="terms-container">
        <h1 className="title">{t("terms.title")}</h1>
        <h2 className="subtitle">{t("terms.subtitle")}</h2>

        <h3 className="article-title">{t("terms.article1.title")}</h3>
        <p className="article-text">{t("terms.article1.text1")}</p>
        <p className="article-text">{t("terms.article1.text2")}</p>
        <ul className="article-list">
          <li>{t("terms.article1.list1.item1")}</li>
          <li>{t("terms.article1.list1.item2")}</li>
        </ul>
        <p className="article-text">{t("terms.article1.text3")}</p>

        <h3 className="article-title">{t("terms.article2.title")}</h3>
        <p className="article-text">{t("terms.article2.text1")}</p>
        <p className="article-text">{t("terms.article2.text2")}</p>
        <ul className="article-list">
          <li>{t("terms.article2.list1.item1")}</li>
          <li>{t("terms.article2.list1.item2")}</li>
          <li>{t("terms.article2.list1.item3")}</li>
          <li>{t("terms.article2.list1.item4")}</li>
          <li>{t("terms.article2.list1.item5")}</li>
          <li>{t("terms.article2.list1.item6")}</li>
          <li>{t("terms.article2.list1.item7")}</li>
        </ul>

        <h3 className="article-title">{t("terms.article3.title")}</h3>
        <p className="article-text">{t("terms.article3.text1")}</p>
        <ul className="article-list">
          <li>{t("terms.article3.list1.item1")}</li>
          <li>{t("terms.article3.list1.item2")}</li>
        </ul>
        <p className="article-text">{t("terms.article3.text2")}</p>
        <p className="article-text">{t("terms.article3.text3")}</p>
        <ul className="article-list">
          <li>{t("terms.article3.list2.item1")}</li>
          <li>{t("terms.article3.list2.item2")}</li>
        </ul>
        <p className="article-text">{t("terms.article3.text4")}</p>

        <h3 className="article-title">{t("terms.article4.title")}</h3>
        <p className="article-text">{t("terms.article4.text1")}</p>
        <p className="article-text">{t("terms.article4.text2")}</p>
        <p className="article-text">{t("terms.article4.text3")}</p>
        <p className="article-text">{t("terms.article4.text4")}</p>

        <h3 className="article-title">{t("terms.article5.title")}</h3>
        <p className="article-text">{t("terms.article5.text1")}</p>
        <p className="article-text">{t("terms.article5.text2")}</p>
        <p className="article-text">{t("terms.article5.text3")}</p>
        <p className="article-text">{t("terms.article5.text4")}</p>

        <h3 className="article-title">{t("terms.article6.title")}</h3>
        <p className="article-text">{t("terms.article6.text1")}</p>
        <p className="article-text">{t("terms.article6.text2")}</p>
        <p className="article-text">{t("terms.article6.text3")}</p>
        <p className="article-text">{t("terms.article6.text4")}</p>
        <p className="article-text">{t("terms.article6.text5")}</p>

        <h3 className="article-title">{t("terms.article7.title")}</h3>
        <p className="article-text">{t("terms.article7.text1")}</p>
        <p className="article-text">{t("terms.article7.text2")}</p>
        <p className="article-text">{t("terms.article7.text3")}</p>
        <p className="article-text">{t("terms.article7.text4")}</p>
        <ul className="article-list">
          <li>{t("terms.article7.list1.item1")}</li>
          <li>{t("terms.article7.list1.item2")}</li>
          <li>{t("terms.article7.list1.item3")}</li>
          <li>{t("terms.article7.list1.item4")}</li>
        </ul>

        <h3 className="article-title">{t("terms.article8.title")}</h3>
        <p className="article-text">{t("terms.article8.text1")}</p>
        <p className="article-text">{t("terms.article8.text2")}</p>

        <h3 className="article-title">{t("terms.article9.title")}</h3>
        <p className="article-text">{t("terms.article9.text1")}</p>

        <h3 className="article-title">{t("terms.article10.title")}</h3>
        <p className="article-text">{t("terms.article10.text1")}</p>

        <h3 className="article-title">{t("terms.article11.title")}</h3>
        <p className="article-text">{t("terms.article11.text1")}</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
