import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/FeedbackForm.css";
import Waves from "../components/Waves";
import CustomButton from "../components/CustomButton";
import FormLabel from "../components/FormLabel";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FeedbackForm = () => {
  const [values, setValues] = useState({
    name: null,
    email: null,
    feedback: null,
  });
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const checkValidity = () => {
    if (!values.name || !values.email || !values.feedback) {
      toast.error("All fields are required!");
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(values.email)) {
      toast.error("Invalid email address!");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkValidity()) {
      setSubmitted(true);
      toast.success("Feedback submitted successfully!");

      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/feedback/send-feedback`,
          { feedback: values.feedback, email: values.email, name: values.name },
          { headers: { "Content-Type": "application/json" } }
        )
        .then(() => {
          setTimeout(() => {
            localStorage.removeItem("token");
            navigate("/login");
          }, 3000);
        });
    }
  };

  return (
    <div className="feedback-container">
      <div className="feedback-form-container">
        <ToastContainer />
        <p className="feedback-form-title">We Value Your Feedback</p>
        <p className="feedback-form-subtitle">
          Please share your thoughts with us.
        </p>
        <div className="feedback-feedbackForm">
          {submitted && (
            <div className="feedback-success-message">
              <h3>Thank you, {values.name}!</h3>
              <div>Your feedback has been submitted.</div>
            </div>
          )}

          {!submitted && (
            <div className="feedback-form-group">
              <div>
                <FormLabel text="Name" required />
                <input
                  className="feedback-form-field"
                  type="text"
                  placeholder="Name"
                  required
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <FormLabel text="Email" required />
                <input
                  className="feedback-form-field"
                  type="email"
                  placeholder="Email"
                  required
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <FormLabel text="Feedback" required />
                <textarea
                  className="feedback-form-field area"
                  placeholder="Your feedback"
                  required
                  name="feedback"
                  value={values.feedback}
                  onChange={handleInputChange}
                />
              </div>

              <CustomButton onClick={handleSubmit} label={"Submit Feedback"} />
            </div>
          )}
        </div>
      </div>
      <Waves />
    </div>
  );
};

export default FeedbackForm;
